var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "form-container" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search_form",
              attrs: { model: _vm.searchForm, inline: "" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "更新日期:", prop: "filter.created_at" } },
                [
                  _c("el-date-picker", {
                    staticClass: "date-picker_width",
                    staticStyle: { width: "120px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      "unlink-panels": true,
                      placeholder: "选择日期"
                    },
                    model: {
                      value: _vm.searchForm.filter.created_at,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm.filter, "created_at", $$v)
                      },
                      expression: "searchForm.filter.created_at"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备类型:", prop: "filter.type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "110px" },
                      attrs: { clearable: "", placeholder: "请选择类型" },
                      model: {
                        value: _vm.searchForm.filter.type,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm.filter, "type", $$v)
                        },
                        expression: "searchForm.filter.type"
                      }
                    },
                    _vm._l(_vm.chargeType, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态:", prop: "filter.status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "110px" },
                      attrs: { clearable: "", placeholder: "请选择状态" },
                      model: {
                        value: _vm.searchForm.filter.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm.filter, "status", $$v)
                        },
                        expression: "searchForm.filter.status"
                      }
                    },
                    _vm._l(_vm.lineStatus, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "桩管理状态:", prop: "filter.control_state" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "110px" },
                      attrs: { clearable: "", placeholder: "请选择桩管理状态" },
                      model: {
                        value: _vm.searchForm.filter.control_state,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm.filter, "control_state", $$v)
                        },
                        expression: "searchForm.filter.control_state"
                      }
                    },
                    _vm._l(_vm.controlStateOption, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小区:", prop: "filter.property_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入小区搜索",
                        "remote-method": _vm.remoteProperty,
                        loading: _vm.propertyLoading,
                        clearable: ""
                      },
                      model: {
                        value: _vm.searchForm.filter.property_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm.filter, "property_id", $$v)
                        },
                        expression: "searchForm.filter.property_id"
                      }
                    },
                    _vm._l(_vm.propertyOptions, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "输入:", prop: "keyword" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "设备ID/固件版本/电桩名称"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleSearch($event)
                      }
                    },
                    model: {
                      value: _vm.searchForm.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "keyword", $$v)
                      },
                      expression: "searchForm.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "省市区：" } },
                [
                  _vm.searchForm.addresName
                    ? _c(
                        "span",
                        {
                          staticClass: "update-item",
                          staticStyle: {
                            padding: "0 15px",
                            "border-radius": "4px"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.searchForm.adrName) +
                              "\n          "
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "right",
                                "padding-top": "5px"
                              },
                              on: { click: _vm.editAddr }
                            },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "edit" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-select",
                    {
                      staticClass: "w-150",
                      attrs: { placeholder: "请选择省" },
                      on: {
                        change: function($event) {
                          return _vm.handleCityChange(0, $event)
                        },
                        focus: function($event) {
                          return _vm.handleSelectCity("null", 0)
                        }
                      },
                      model: {
                        value: _vm.citySelect.province,
                        callback: function($$v) {
                          _vm.$set(_vm.citySelect, "province", $$v)
                        },
                        expression: "citySelect.province"
                      }
                    },
                    _vm._l(_vm.cityOptions.provinceOps, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  ),
                  _vm.citySelect.province
                    ? _c(
                        "el-select",
                        {
                          staticClass: "w-150",
                          attrs: { placeholder: "请选择市" },
                          on: {
                            change: function($event) {
                              return _vm.handleCityChange(1, $event)
                            },
                            focus: function($event) {
                              return _vm.handleSelectCity(
                                _vm.citySelect.province,
                                1
                              )
                            }
                          },
                          model: {
                            value: _vm.citySelect.city,
                            callback: function($$v) {
                              _vm.$set(_vm.citySelect, "city", $$v)
                            },
                            expression: "citySelect.city"
                          }
                        },
                        _vm._l(_vm.cityOptions.cityOps, function(item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.citySelect.city
                    ? _c(
                        "el-select",
                        {
                          staticClass: "w-150",
                          attrs: { placeholder: "请选择区" },
                          on: {
                            change: function($event) {
                              return _vm.handleCityChange(2, $event)
                            },
                            focus: function($event) {
                              return _vm.handleSelectCity(
                                _vm.citySelect.city,
                                2
                              )
                            }
                          },
                          model: {
                            value: _vm.citySelect.region,
                            callback: function($$v) {
                              _vm.$set(_vm.citySelect, "region", $$v)
                            },
                            expression: "citySelect.region"
                          }
                        },
                        _vm._l(_vm.cityOptions.regionOps, function(item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.citySelect.region
                    ? _c(
                        "el-select",
                        {
                          staticClass: "w-150",
                          attrs: { placeholder: "请选择街道" },
                          on: {
                            change: function($event) {
                              return _vm.handleCityChange(3, $event)
                            },
                            focus: function($event) {
                              return _vm.handleSelectCity(
                                _vm.citySelect.region,
                                3
                              )
                            }
                          },
                          model: {
                            value: _vm.citySelect.street,
                            callback: function($$v) {
                              _vm.$set(_vm.citySelect, "street", $$v)
                            },
                            expression: "citySelect.street"
                          }
                        },
                        _vm._l(_vm.cityOptions.streetOps, function(item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.resetForm("searchForm")
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "batch-container" },
        [
          _c(
            "router-link",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "admin:charging:add",
                  expression: "'admin:charging:add'"
                }
              ],
              attrs: { to: "/charge/equipment/edit", tag: "span" }
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "m-r-30",
                  attrs: { size: "mini", icon: "el-icon-plus", type: "primary" }
                },
                [_vm._v("设备")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "batch-btn" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "admin:charging:add",
                      expression: "'admin:charging:add'"
                    }
                  ],
                  attrs: {
                    size: "small",
                    disabled: !(
                      _vm.selectList.length > 0 || _vm.selectTotal.length > 0
                    )
                  },
                  on: { click: _vm.batchMetter }
                },
                [_vm._v("远程抄表")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "admin:charging:add",
                      expression: "'admin:charging:add'"
                    }
                  ],
                  attrs: {
                    size: "small",
                    disabled: !(
                      _vm.selectList.length > 0 || _vm.selectTotal.length > 0
                    )
                  },
                  on: { click: _vm.batchFirm }
                },
                [_vm._v("更新固件")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    disabled: !(
                      _vm.selectList.length > 0 || _vm.selectTotal.length > 0
                    )
                  },
                  on: { click: _vm.batchQrcode }
                },
                [_vm._v("下载二维码")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              attrs: {
                data: _vm.dataList,
                border: "",
                "row-key": "id",
                "highlight-current-row": _vm.hightlight
              },
              on: {
                "selection-change": _vm.handleSelectChange,
                "cell-mouse-enter": _vm.handleReturn,
                "cell-mouse-leave": _vm.handleReturn
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  "header-align": "center",
                  align: "center",
                  width: "60px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "名称/小区",
                  "min-width": "120px",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c("div", [_vm._v(_vm._s(scope.row.name))]),
                          _c("div", [
                            _vm._v("[" + _vm._s(scope.row.property_name) + "]")
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "设备ID/类型",
                  width: "120px",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c("div", [_vm._v(_vm._s(scope.row.number))]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.transChargeType(scope.row.type)))
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "充电口",
                  width: "60px",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.port_count || _vm.暂无))
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "电表信息",
                  "header-align": "center",
                  align: "left"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticStyle: { "padding-left": "10px" } }, [
                          _c("div", [
                            _vm._v(
                              "电表数据:" + _vm._s(scope.row.meter_data) + " 度"
                            )
                          ]),
                          _c("div", [
                            _vm._v(
                              "抄表时间:" +
                                _vm._s(scope.row.meter_last_time || "-")
                            )
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "省市区",
                  "header-align": "center",
                  align: "center",
                  prop: "property_region"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "设备信息",
                  "header-align": "center",
                  align: "left"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticStyle: { "padding-left": "10px" } }, [
                          _c("div", [
                            _vm._v(
                              "固件信息:" + _vm._s(scope.row.client_v || "-")
                            )
                          ]),
                          _c("div", [
                            _vm._v("SIM卡号:" + _vm._s(scope.row.iccid || "-"))
                          ]),
                          _c("div", [
                            _vm._v("信号值:" + _vm._s(scope.row.rssi || "-"))
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "last_link_time",
                  label: "最后连接时间",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c(
                            "span",
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type:
                                      scope.row.status == 1 ? "success" : "info"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.transOnline(scope.row.status))
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.transDeviceState(scope.row.device_state)
                              )
                            )
                          ]),
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v(
                              _vm._s(_vm.controlState(scope.row.control_state))
                            )
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "累计收入",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c("div", [
                            _vm._v("今日:￥" + _vm._s(scope.row.income_today))
                          ]),
                          _c("div", [
                            _vm._v("本月:￥" + _vm._s(scope.row.income_month))
                          ]),
                          _c("div", [
                            _vm._v("本年:￥" + _vm._s(scope.row.income_year))
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "200px",
                  "header-align": "center",
                  align: "left"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { "padding-left": "10px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.batchEdit(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("编辑设备")]
                            ),
                            _c(
                              "el-button",
                              { attrs: { size: "mini" } },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      tag: "span",
                                      to: {
                                        path: "/order/list",
                                        query: { charging_id: scope.row.id }
                                      }
                                    }
                                  },
                                  [_vm._v("订单列表")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-left": "10px",
                              "padding-top": "10px"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "mini" } },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      tag: "span",
                                      to: {
                                        path: "/charge/equipment/meter",
                                        query: { number: scope.row.number }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  抄表记录\n                  "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              { attrs: { size: "mini" } },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    ref: "popover3",
                                    attrs: {
                                      placement: "top",
                                      trigger: "hover"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "center",
                                          margin: "auto"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("qriously", {
                                              attrs: {
                                                value: _vm.transUrl(
                                                  scope.row.number
                                                ),
                                                size: 150
                                              }
                                            }),
                                            _c("div", [
                                              _vm._v(
                                                "设备号：" +
                                                  _vm._s(scope.row.number)
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.createQr(
                                                      scope.row.number
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("下载")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "reference" },
                                        slot: "reference"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  二 维  码\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-left": "10px",
                              "padding-top": "10px"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleControlDevice(scope.row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              设备控制\n             "
                                )
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handleDel(scope.row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                删除设备\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.totalPage > 0,
            expression: "totalPage>0"
          }
        ],
        attrs: {
          total: _vm.totalPage,
          page: _vm.searchForm.page,
          limit: _vm.searchForm.per_page
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchForm, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchForm, "per_page", $event)
          },
          pagination: _vm.getTableData
        }
      }),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "has",
              rawName: "v-has",
              value: "admin:charging:add",
              expression: "'admin:charging:add'"
            }
          ],
          attrs: { visible: _vm.firmDialog, title: "更新固件" },
          on: {
            "update:visible": function($event) {
              _vm.firmDialog = $event
            },
            close: _vm.handleFirmClose
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-steps",
                {
                  staticStyle: { "padding-bottom": "20px" },
                  attrs: {
                    active: _vm.firmActive,
                    "finish-status": "success",
                    "process-status": "finish"
                  }
                },
                [
                  _c("el-step", { attrs: { title: "选择固件" } }),
                  _c("el-step", { attrs: { title: "开始更新" } }),
                  _c("el-step", { attrs: { title: _vm.firmSetpFinishTitle } })
                ],
                1
              ),
              _vm.firmActive == 0
                ? _c(
                    "el-form",
                    {
                      ref: "firmForm",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        inline: "",
                        model: _vm.firmForm,
                        "show-message": true
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择固件:",
                            prop: "firmware_id",
                            rules: [{ required: true, message: "请选择" }]
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择固件" },
                              on: { focus: _vm.handleFirmList },
                              model: {
                                value: _vm.firmForm.firmware_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.firmForm, "firmware_id", $$v)
                                },
                                expression: "firmForm.firmware_id"
                              }
                            },
                            _vm._l(_vm.firmOptions, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.firmActive != 0
                ? _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.firmUpList,
                        "row-class-name": _vm.tableRowClassName
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "id",
                          label: "ID",
                          "header-align": "center",
                          align: "center",
                          width: "60px"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "名称/小区",
                          "header-align": "left",
                          align: "left"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _c("div", [_vm._v(_vm._s(scope.row.name))]),
                                    _c("div", [
                                      _vm._v(
                                        "[" +
                                          _vm._s(scope.row.property_name) +
                                          "]"
                                      )
                                    ])
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2713181542
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "设备ID/类型",
                          "header-align": "left",
                          align: "left"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _c("div", [
                                      _vm._v(_vm._s(scope.row.number))
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.transChargeType(scope.row.type)
                                        )
                                      )
                                    ])
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3139086810
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "固件信息",
                          prop: "client_v",
                          "header-align": "left",
                          align: "left"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "firmware_status",
                          label: "更新状态",
                          width: "80px",
                          "header-align": "left",
                          align: "left"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.firmware_status == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "info" } },
                                        [_vm._v("无更新")]
                                      )
                                    : _vm._e(),
                                  scope.row.firmware_status == 1
                                    ? _c("el-tag", [_vm._v("开始更新")])
                                    : _vm._e(),
                                  scope.row.firmware_status == 2
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "warning" } },
                                        [_vm._v("更新中")]
                                      )
                                    : _vm._e(),
                                  scope.row.firmware_status == 3
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("完成")]
                                      )
                                    : _vm._e(),
                                  scope.row.firmware_status == 99
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("更新失败")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2200377030
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "firmware_progress",
                          label: "更新进度",
                          width: "80px",
                          "header-align": "left",
                          align: "left"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.firmware_status == 3
                                    ? _c(
                                        "span",
                                        [
                                          scope.row.firmware_progress == 0
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                确认成功\n              "
                                                )
                                              ])
                                            : _vm._e(),
                                          scope.row.firmware_progress == 0
                                            ? _c("el-tag", [_vm._v("确认成功")])
                                            : _vm._e(),
                                          scope.row.firmware_progress == 1
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "danger" } },
                                                [_vm._v("确认失败")]
                                              )
                                            : _vm._e(),
                                          scope.row.firmware_progress == 2
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "success" } },
                                                [_vm._v("接收完成")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              scope.row.firmware_progress
                                            ) +
                                            "%\n            "
                                        )
                                      ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3692987399
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer-container",
              staticStyle: { "padding-top": "10px" }
            },
            [
              _c("el-button", { on: { click: _vm.handleFirmClose } }, [
                _vm._v("关闭")
              ]),
              _vm.firmActive == 0
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleFirmBtn }
                    },
                    [_vm._v("下发更新")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("control-device", { ref: "controlDevice" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }