//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchChargingPortList, sendChargingControl } from "@/api/charge";
import { transChargeType, transOnline, transDeviceState, controlState } from "@/utils/util";
export default {
  name: "ControlDevice",
  data: function data() {
    return {
      title: "设备控制",
      dialogVisible: false,
      charging: {},
      portList: [],
      tableLoading: false
    };
  },
  methods: {
    show: function show(row) {
      var _this = this;

      this.charging = row;
      this.dialogVisible = true;
      fetchChargingPortList(row.id).then(function (response) {
        _this.portList = response.data;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.$message({
          type: "warning",
          message: err.data["message"]
        });

        _this.tableLoading = false;
      });
    },
    close: function close() {
      this.dialogVisible = false;
    },
    handleSendControl: function handleSendControl(charging_id, action, value) {
      var _this2 = this;

      sendChargingControl(charging_id, {
        action: action,
        value: value
      }).then(function (response) {
        _this2.$message({
          type: "success",
          center: true,
          message: "下发成功"
        });
      }).catch(function (err) {
        _this2.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    transChargeType: transChargeType,
    transOnline: transOnline
  }
};